import React from "react";
import styled from "styled-components";

import { arrow, line } from '@utils/keyframes';
import { headerAnimationDuration } from "@utils/variables";

const StyledArrow = styled.path`
  transform: scale(0);
  animation: ${arrow} ${headerAnimationDuration}s 2.4s infinite;
`;

const StyledLine = styled.path<{ animationDelay: number }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${line} ${headerAnimationDuration}s infinite;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const DetailsRedArrow = () => (
  <svg viewBox="0 0 153 229" preserveAspectRatio="xMidYMid slice">
    <g strokeLinejoin="round" stroke="#761113" strokeWidth="0.25" style={{ transform: 'matrix(1,0,0,1,117.88099670410156,6.47599983215332)' }}>
      <StyledLine animationDelay={ 2.2 } d=" M-34.582000732421875,6.473999977111816 C-34.582000732421875,6.473999977111816 34.577999114990234,-6.47599983215332 34.577999114990234,-6.47599983215332" />
    </g>
    <g strokeLinejoin="round" stroke="#761113" strokeWidth="0.25" fillOpacity="0" style={{ transform: 'matrix(1,0,0,1,108.06900024414062,51.0890007019043)' }}>
      <StyledLine animationDelay={ 1.4 } d=" M10.222999572753906,0.008999999612569809 C10.222999572753906,5.658999919891357 5.6529998779296875,10.229000091552734 -0.007000000216066837,10.229000091552734 C-5.6570000648498535,10.229000091552734 -10.22700023651123,5.658999919891357 -10.22700023651123,0.008999999612569809 C-10.22700023651123,-5.651000022888184 -5.6570000648498535,-10.230999946594238 -0.007000000216066837,-10.230999946594238 C5.6529998779296875,-10.230999946594238 10.222999572753906,-5.651000022888184 10.222999572753906,0.008999999612569809z" />
    </g>
    <g style={{ transform: 'matrix(1,0,0,1,95.01899719238281,123.81600189208984)' }}>
      <StyledArrow fill="#ed3e2e" d=" M13.185999870300293,-33.715999603271484 C13.185999870300293,-33.715999603271484 -23.27400016784668,16.663999557495117 -23.27400016784668,16.663999557495117 C-23.27400016784668,16.663999557495117 23.275999069213867,33.7140007019043 23.275999069213867,33.7140007019043 C23.275999069213867,33.7140007019043 13.185999870300293,-33.715999603271484 13.185999870300293,-33.715999603271484z" />
    </g>
    <g strokeLinejoin="round" stroke="#761113" strokeWidth="0.25" fillOpacity="0" style={{ transform: 'matrix(1,0,0,1,48.92100143432617,180.8260040283203)' }}>
      <StyledLine animationDelay={ 2 } d=" M-48.92100143432617,47.18199920654297 C-48.92100143432617,47.18199920654297 -13.300999641418457,36.652000427246094 7.789000034332275,16.631999969482422 C41.27899932861328,-15.208000183105469 48.91899871826172,-47.178001403808594 48.91899871826172,-47.178001403808594" />
    </g>
  </svg>
);

export default DetailsRedArrow;
