import React, { ReactNode } from "react";
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { breakpoints } from "@utils/variables";
import i18n from '@components/i18n';
import tw from "twin.macro";
import '@styles/tailwind.css';
import '@styles/main.scss';

import Header from '@components/header';
import Footer from '@components/footer';

type LayoutProps = {
  children: ReactNode,
  boxHeight?: number,
  hideFooter?: boolean
};

const OverflowHidden = tw.div`overflow-hidden`;

const Container = tw.div`container min-h-440 mx-auto xxl:max-w-1280!`;

const BoxBlue = tw.div`hidden lg:block lg:absolute lg:top-0 lg:right-0 lg:w-1/2 lg:bg-lightBlue`;

const Layout = ({ children, boxHeight, hideFooter = false }: LayoutProps) => {
  return (
    <I18nextProvider i18n={ i18n }>
      <ThemeProvider theme={{ breakpoints }}>
        {boxHeight && <BoxBlue style={{ height: `${boxHeight}px` }} />}
        <OverflowHidden>
          <Header />
          <Container>
            <main>{children}</main>
            {!hideFooter && <Footer />}
          </Container>
        </OverflowHidden>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default Layout;
