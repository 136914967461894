import React from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

const animationDuration = 6.5;

const arrowAnimation = keyframes`
  0% { transform: scale(0) rotate(40deg); }
  2% { transform: scale(0) rotate(40deg); }
  7% { transform: none; }
  74% { transform: none; }
  80% { transform: scale(0) rotate(40deg); }
  100% { transform: scale(0) rotate(40deg); }
`;

const pathLengthShort = keyframes`
  0% { stroke-dashoffset: 1000; }
  2% { stroke-dashoffset: 1000; }
  10% { stroke-dashoffset: 900; }
  74% { stroke-dashoffset: 900; }
  80% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;

const pathLength = keyframes`
  0% { stroke-dashoffset: 1000; }
  4% { stroke-dashoffset: 1000; }
  8% { stroke-dashoffset: 800; }
  78% { stroke-dashoffset: 800; }
  84% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;

const StyledImg = tw.svg`w-full`;

const StyledArrow = styled.polygon`
  transform: scale(0) rotate(40deg);
  transform-origin: 100px 250px;
  animation: ${arrowAnimation} ${animationDuration}s .1s infinite;
`;

const StyledLine = styled.path<{ animationDelay: number, shortAnimation?: boolean }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${animationDuration}s .2s infinite;
  animation-delay: ${({ animationDelay }) => animationDelay && `${animationDelay}s`};
  animation-name: ${({ shortAnimation }) => shortAnimation ? pathLengthShort : pathLength };
`;

const ArrowRed = () => (
  <StyledImg viewBox="0 0 300 320">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="#761113" strokeWidth="0.25" strokeLinejoin="round">
        <StyledLine shortAnimation animationDelay={ .2 } d="M 112 0 l -93 17" />
        <StyledLine animationDelay={ 0 } d="M130,304 C130,304 177.690313,289.941212 205.923181,263.209358 C250.77253,220.692861 261,178 261,178" />
        <StyledLine shortAnimation animationDelay={ .2 } d="M27,68.0064546 C27,75.7390503 20.9626556,82 13.4937759,82 C6.0373444,82 0,75.7390503 0,68.0064546 C0,60.2609497 6.0373444,54 13.4937759,54 C20.9626556,54 27,60.2609497 27,68.0064546 Z" />
      </g>
      <StyledArrow fill="#EE3F2E" points="83.5599676 120 35 187.23559 97 210" />
    </g>
  </StyledImg>
);

export default ArrowRed;
